<template>
<div class="type-level">
  <BaseConfig :task="task" :mapId="mapId" :isEdit="isEdit" :levels="levels" :detail="detail" @change="changeData"></BaseConfig>
  <div class="display_flex mt10">
    <span class="label-name">设置试卷及格分数: </span>
    <div class="display_flex">
      <el-input v-model="passScore" :disabled="!isEdit" type="number" placeholder="请输入" class="mr10 w120" @change="changePassScore" />
      <span>分</span>
    </div>
  </div>
</div>
</template>

<script>
import BaseConfig from './baseConfig';
export default {
  name: "typeLevel",
  data(){
    return{
      passScore:'',
      level:[],
      dataType:[],
    }
  },
  props:{
    task:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    mapId:{
      type:String,
      default:''
    },
    levels:{
      type:Array,
      default:()=>{
        return []
      }
    },
    detail:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  components:{
    BaseConfig
  },
  watch:{
    detail:{
      handler(newV){
        if(newV.id){
          this.passScore = newV.pass_score;
        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{
    changeData(data){
      this.level = data.level;
      this.dataType = data.types;

      this.$emit('change',{level:this.level,types:this.dataType,passScore:this.passScore});

    },
    changePassScore(){
      this.$emit('change',{level:this.level,types:this.dataType,passScore:this.passScore});
    }
  }
}
</script>

<style scoped lang="scss">
.type-level{
  font-size: 14px;
  .display_flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .label-name{
    margin-right: 10px;
    font-weight: bold;
    color: #333333;
  }
  .w120{
    width: 120px;
  }
}
</style>
