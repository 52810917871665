<template>
<div class="base-config">
  <div class="data-num">已选章节共计可抽取 <span>{{ dataTotalNum }}题</span> 生成试卷</div>
  <div class="base-config-tit"><span>设置试题难度：</span>（可多选）</div>
  <div class="base-config-level">
    <div :class="item.status ? 'active' : ''" v-for="(item,index) in levels" :key="item.id" @click="changeLevel(index)">{{ item.name }}</div>
  </div>
  <div class="base-config-tit" v-if="levelIds"><span>试卷题型数量及分数：</span></div>
  <div class="mb10 mt10" v-if="levelIds">请选择要抽题的题型：</div>
  <el-checkbox-group
      v-model="checkedTypes"
      @change="changeType"
      :disabled="!isEdit"
      v-if="levelIds"
  >
    <el-checkbox v-for="item in dataTypes" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
  </el-checkbox-group>
  <div class="mt20 mb10" v-if="checkedTypes.length && levelIds">请设置分值及各题型抽题数量：</div>
  <div class="base-config-total" v-if="checkedTypes.length && levelIds">当前设置试题共计 <span class="base-config-total-num">{{totalData.num}}</span> 题  <span class="base-config-total-fgx">|</span> 总分 <span class="base-config-total-num">{{totalData.score}}</span>分</div>
  <div class="base-config-type" v-if="levelIds">
    <div v-for="(item,index) in dataTypes" :key="item.id">
      <div class="base-config-type-item" v-if="item.status">
        <div class="base-config-type-item-tit">{{ item.name }}<span>（可选择{{ item.num }}题  |  共计{{item.val * item.score}}分）</span></div>
        <div class="base-config-type-item-num">
          <span>题目数量：</span>
          <el-input v-model="item.val" :disabled="!isEdit" type="number" min="0" :max="item.num" size="small" @blur="checkNum(index,'val')" placeholder="请输入" class="base-config-type-item-num-inp"></el-input>
          <span>题</span>
        </div>
        <div class="base-config-type-item-num">
          <span>设置分数：</span>
          <el-input v-model="item.score" :disabled="!isEdit" type="number" min="0" size="small" placeholder="请输入" @blur="checkNum(index,'score')" class="base-config-type-item-num-inp"></el-input>
          <span>分/题</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "baseConfig",
  data(){
    return{
      curLevel:{},
      dataTotalNum:0,
      dataTypes:[],
      checkedTypes: [],
      levelIds:'',
    }
  },
  props:{
    task:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    mapId:{
      type:String,
      default:''
    },
    levels:{
      type:Array,
      default:()=>{
        return []
      }
    },
    detail:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  computed:{
    totalData(){
      let totalNum = 0;
      let totalScore = 0;
      this.dataTypes.forEach((item)=>{
        totalNum += Number(item.val);
        totalScore += Number(item.val) * Number(item.score);
      });
      return {
        num:totalNum,
        score:totalScore
      }
    }
  },
  watch:{
    task:{
      handler(newV){
        if(newV.id){
          this.formatLevel();
          this.getDataNum();

        }
      },
      immediate:true,
    },
  },
  created(){

  },
  methods:{
    getDataNum(type){
      let params = {
        map_id:this.mapId,
        node_id:this.detail.attr.map_node_ids
      };
      if(this.levelIds){
        params.data_level_str = this.levelIds;
      }
      this.api.dataset.datasetCategoryDataCount(params).then((res)=>{
        this.dataTotalNum = res.data_count;
        this.dataTypes = [];
        this.checkedTypes = [];
        for(let name in res.data_types){
          let arr = res.data_types_count.filter((item)=>{
            return item.type == name;
          });
          let index = this.dataTypes.findIndex((item)=>{
            return item.id == name;
          })


          if(index > -1){
            this.dataTypes[index].num = arr.length ? arr[0].count : '0';
          }else{
            this.dataTypes.push({
              name:res.data_types[name],
              id:name,
              val:0,
              score:0,
              num:arr.length ? arr[0].count : '0',
            })
          }


        }
        if(!type){
          this.initData();
        }else{
          this.dataTypes = this.dataTypes.filter((item)=>{
            return item.num > 0
          })
        }


      })
    },

    formatLevel(){

      let levelArr = this.detail.level_str.split(',') || [];
      this.levels.forEach((item)=>{
        let arr = levelArr.filter((sItem)=>{
          return sItem == item.id;
        })
        if(arr.length){
          this.$set(item,'status',true);
        }else{
          this.$set(item,'status',false);
        }

      })
      this.levelIds = this.levels.filter((item)=>{
        return item.status;
      }).map((item)=>{
        return item.id;
      }).join(',');
      // this.$emit('change',{level:this.levels,types:this.dataTypes});
    },
    initData() {

      let arr = this.detail.type_str.split(',') || [];
      let scoreArr = this.detail.score_str.split(',') || [];

      this.dataTypes.forEach((item, index) => {
        item.val = arr[index] || 0;
        item.score = scoreArr[index] || 0;
        if(arr[index] > 0){
          this.$set(item,'status',true);
          this.checkedTypes.push(item.id);
        }else{
          this.$set(item,'status',false);
        }
      })


      this.$emit('change',{level:this.levels,types:this.dataTypes});
    },
    changeLevel(index){
      if(!this.isEdit) return;
      this.levels[index].status = !this.levels[index].status;
      this.levelIds = this.levels.filter((item)=>{
        return item.status;
      }).map((item)=>{
        return item.id;
      }).join(',');
      if(this.levelIds){
        this.getDataNum(this.levelIds,'getNum');
      }
      this.$emit('change',{level:this.levels,types:this.dataTypes});
    },
    changeType(data){

      this.dataTypes.forEach((item)=>{
        let arr = data.filter((sItem)=>{
          return sItem == item.id;
        })
        if(arr.length){
          this.$set(item,'status',true);
        }else{
          this.$set(item,'status',false);
          item.val = 0;
          item.score = 0;
        }
      })
      this.$emit('change',{level:this.levels,types:this.dataTypes});
    },
    checkNum(index,name){

      if(name == 'val'&& Number(this.dataTypes[index].val) > Number(this.dataTypes[index].num) ){
        this.$Message.warning('输入数量不能大于可选数量');
        this.dataTypes[index][name] = 0;
        return;
      }
      if(!util.isInte(Number(this.dataTypes[index][name]))){
        this.$Message.warning('请输入正整数');
        this.dataTypes[index][name] = 0;
      }
      this.$emit('change',{level:this.levels,types:this.dataTypes});
    }
  }
}
</script>

<style scoped lang="scss">
.base-config{
  .data-num{
    margin-bottom: 20px;
    padding-left: 14px;
    width: 400px;
    height: 40px;
    background: #F6F7FA;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
      color:#5782FF;
      font-weight: bold;
      padding: 0 10px;
    }
  }
  .base-config-tit{
    font-size: 14px;
    color: #999999;
    >span{
      font-weight: bold;
      color: #5782FF;
    }
  }
  .base-config-level{
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 10px;
      width: 80px;
      height: 34px;
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      border-radius: 4px;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active{
      background: #5782FF;
      color: #FFFFFF;
      border: 1px solid #5782FF;
    }
  }
  .base-config-total{
    color: #666666;
    .base-config-total-num{
      padding: 0 5px;
      font-weight: bold;
      color: #5782FF;
    }
    .base-config-total-fgx{
      padding: 0 10px;
    }
  }
  .base-config-type{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;


    .base-config-type-item{
      margin-right: 10px;
      margin-bottom: 10px;
      padding:14px 20px;
      width: 290px;
      height: 140px;
      background: #F6F7FA;
      border-radius: 6px;
      .base-config-type-item-tit{
        height: 28px;
        border-bottom: 1px solid #E3E3E3;
        font-weight: bold;
        >span{
          font-weight: 400;
          color: #666666;
        }
      }
      .base-config-type-item-num{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #333333;
        .base-config-type-item-num-inp{
          margin-right: 7px;
          width: 90px;
        }
      }
    }
  }
}
</style>
