<template>
<div class="chapter-map">
  <div class="display_flex mt10">
    <span class="label-name">设置试卷及格分数: </span>
    <div class="display_flex">
      <el-input v-model="passScore" :disabled="!isEdit" type="number" placeholder="请输入" class="mr10 w120" @change="changeData" />
      <span>分</span>
    </div>
  </div>
  <div class="data-num mt20">已选章节共计可抽取 <span>{{ dataTotalNum }}题</span> 生成试卷</div>
<!--  <div class="base-config-tit"><span>设置试题难度：</span>（可多选）</div>-->
<!--  <div class="base-config-level">-->
<!--    <div :class="item.status ? 'active' : ''" v-for="(item,index) in levels" :key="item.id" @click="changeLevel(index)">{{ item.name }}</div>-->
<!--  </div>-->
  <div class="base-config-tit" ><span>试卷题型数量及分数：</span></div>
  <div class="mb10 mt10" >请选择要抽题的题型：</div>
  <el-checkbox-group
      v-model="checkedTypes"
      @change="changeType"
      :disabled="!isEdit"
  >
    <el-checkbox v-for="item in dataTypes" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
  </el-checkbox-group>
  <div class="mt20 mb10 types-set-tit" v-if="checkedTypes.length">
    <div class="types-set-tit-str">请设置分值及各题型抽题数量：</div>
    <div class="types-set-tit-num">当前设置试题共计 <span>{{totalData.num}}</span> 题  | 总分 <span>{{totalData.score}}</span>分</div>
  </div>
  <div>
    <div v-for="item in dataTypes" :key="item.id + '1'">
      <div class="exam-type" v-if="item.status">
        <div class="exam-type-top">
          <p>{{ item.name }}（共 {{item.totalNum}} 题，共 {{item.totalNum * item.singleScore}} 分）</p>
          <div class="exam-type-top-r">
            <p class="exam-type-top-r-t">设置题型单题分数：</p>
            <el-input type="number" class="el-input" :disabled="!isEdit" v-model="item.singleScore" :min="0" :max="10" placeholder="请输入" @change="changeScore"/>
            <p>分/题</p>
          </div>
        </div>
        <div class="exam-type-cont">
          <div class="exam-type-cont-set" v-if="!item.dataList.length">
            <p>设置考试抽题规则 </p>
            <Button type="primary" @click="setDataNum(item.id)">设置</Button>
          </div>
          <div class="exam-type-cont-edit" v-else>
            <div class="exam-type-cont-nr">
              <p class="exam-type-cont-nr-tit">难度抽题数量:</p>
              <p v-for="sItem in item.dataList" :key="sItem.id + 'radio'">{{ (sItem.node && sItem.node.name) || sItem.lastName }}：<span v-if="sItem.noLimit > 0">不限 {{sItem.noLimit}} 题</span><span v-if="sItem.simpleNum > 0">简单 {{sItem.simpleNum}} 题</span><span v-if="sItem.generalNum > 0">一般 {{sItem.generalNum}} 题</span><span v-if="sItem.difficultyNum > 0">困难 {{sItem.difficultyNum}} 题</span></p>
            </div>
            <Button type="primary" @click="setDataNum(item.id)">编辑</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
      v-model="dialogVisible"
      title="请设置各课程内容难度抽取题数，若数量设置“0”则为不抽取"
      width="1200px"
      :footer-hide="true"
  >
    <div class="table-box-head">
      <p class="set-num-tit">多选题抽取总数量：共{{noLimitTotalNum + simpleTotalNum + generalTotalNum + difficultyTotalNum}}题（不限{{noLimitTotalNum}}题，简单 {{simpleTotalNum}} 题，中等 {{generalTotalNum}} 题，困难 {{difficultyTotalNum}} 题）</p>
      <div>
<!--        <Button @click="cancelDataNum" class="mr10">取消</Button>-->
        <Button type="primary" @click="confirmDataNum">确定</Button>
      </div>
    </div>
    <div class="table-box mt20">
      <table class="table">
        <tr>
          <th v-for="(i,index) in maxLengh" :key="index">
            <div style="height: 40px">
              <p>节点</p>
            </div>
          </th>
          <th>
            <div style="height: 40px">
              <p>不限难度</p>
            </div>
          </th>
          <th>
            <div style="height: 40px">
              <p>简单</p>
            </div>
          </th>
          <th>
            <div style="height: 40px">
              <p>中等</p>
            </div>
          </th>
          <th>
            <div style="height: 40px">
              <p>困难</p>
            </div>
          </th>
        </tr>
        <tr v-for="(item,index) in tableData" :key="item.id">
          <template v-for="(sItem,sIndex) in item">
            <td  :rowspan="sItem.children_count == 0 ? Number(sItem.children_count) + 1 : sItem.children_count" v-if="sItem">
              <div v-if="sItem.node && sItem.id != -1" :style="{height:((!sItem.children_count ||sItem.children_count == 0) ? (Number(sItem.children_count) + 1) * 40 : Number(sItem.children_count) * 40) + (sItem.children_count == 0 ?  0 : Number(sItem.children_count)) + 'px'}">
                <p>{{ sItem.node && sItem.node.name }}</p>
                <!--              <p>{{sItem.weight}}%</p>-->
              </div>
            </td>
          </template>
          <td>
            <el-input type="number" class="mt10" v-model="item[item.length - 1].noLimit" :min="0" :max="item[item.length - 1].noLimits" @change="changeNoLimit(item[item.length - 1])"></el-input>
            <p>可选 {{ item[item.length - 1].noLimits }} 题</p>
          </td>
          <td>
            <el-input type="number" class="mt10" v-model="item[item.length - 1].simpleNum" :min="0" :max="item[item.length - 1].simpleNums" @change="changeSimpleNum(item[item.length - 1],index)"  ></el-input>
            <p>可选 {{ item[item.length - 1].simpleNums }} 题</p>
          </td>
          <td>
            <el-input type="number" class="mt10" v-model="item[item.length - 1].generalNum" :min="0" :max="item[item.length - 1].generalNums" @change="changeGeneralNum(item[item.length - 1])" ></el-input>
            <p>可选 {{ item[item.length - 1].generalNums }} 题</p>
          </td>
          <td>
            <el-input type="number" class="mt10" v-model="item[item.length - 1].difficultyNum" :min="0" :max="item[item.length - 1].difficultyNums" @change="changeDifficultyNum(item[item.length - 1])" ></el-input>
            <p>可选 {{ item[item.length - 1].difficultyNums }} 题</p>
          </td>
        </tr>
      </table>
    </div>


  </Modal>
</div>
</template>

<script>
export default {
  name: "chapter",
  data(){
    return{
      passScore:'',
      dataTotalNum:'',
      dataTypes:[],
      checkedTypes:[],
      dialogVisible:false,
      noLimitTotalNum:0,
      simpleTotalNum:0,
      generalTotalNum:0,
      difficultyTotalNum:0,
      maxLengh:0,
      tableData:[],
      levelIds:'',
    }
  },
  props:{
    task:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    mapId:{
      type:String,
      default:''
    },
    levels:{
      type:Array,
      default:()=>{
        return []
      }
    },
    detail:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  watch:{
    task:{
      handler(newV){
        if(newV.id){
          this.formatLevel();
          this.getDataNum();
        }
      },
      immediate:true,
    },
  },
  computed:{
    totalData(){
      let totalNum = 0;
      let totalScore = 0;
      this.dataTypes.forEach((item)=>{
        totalNum += Number(item.totalNum);
        totalScore += Number(item.totalNum) * Number(item.singleScore);
      });
      return {
        num:totalNum,
        score:totalScore
      }
    }
  },
  methods:{
    changeData(){
      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
    changeType(data){

      this.dataTypes.forEach((item)=>{
        let arr = data.filter((sItem)=>{
          return sItem == item.id;
        })
        if(arr.length){
          this.$set(item,'status',true);
        }else{
          this.$set(item,'status',false);
          item.singleScore = 0;
          item.totalNum = 0;
          item.dataList = 0;
        }
      })

      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
    getDataNum(){
      let params = {
        map_id:this.mapId,
        node_id:this.detail.attr.map_node_ids
      };
      if(this.levelIds){
        params.data_level_str = this.levelIds;
      }
      this.api.dataset.datasetCategoryDataCount(params).then((res)=>{
        this.dataTotalNum = res.data_count;
        this.dataTypes = [];
        for(let name in res.data_types){
          let arr = res.data_types_count.filter((item)=>{
            return item.type == name;
          });
          this.dataTypes.push({
            name:res.data_types[name],
            id:name,
            singleScore:0,
            num:arr.length ? arr[0].count : '0',
            dataList:[],
            totalNum:0,
          })
        }

        this.initData();

      })
    },
    initData() {
      let arr = this.detail.attr.map_node_config || [];

      this.dataTypes.forEach((item, index) => {
        let curArr = arr.filter((sItem)=>{
          return sItem.id == item.id
        });
        if(curArr.length){
          this.$set(item,'status',true);
          this.$set(item,'singleScore',curArr[0].singleScore);
          this.$set(item,'dataList',curArr[0].dataList);
          let total = 0;
          item.dataList.forEach((sItem)=>{
            total += this.sumNum(sItem);
          })
          this.$set(item,'totalNum',total);
          this.checkedTypes.push(item.id);
        }


      })

      this.passScore = this.detail.pass_score;
      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
    formatLevel(){

      let levelArr = this.detail.level_str.split(',') || [];
      this.levels.forEach((item)=>{
        let arr = levelArr.filter((sItem)=>{
          return sItem == item.id;
        })
        if(arr.length){
          this.$set(item,'status',true);
        }else{
          this.$set(item,'status',false);
        }

      })
      this.levelIds = this.levels.filter((item)=>{
        return item.status;
      }).map((item)=>{
        return item.id;
      }).join(',');
      // this.$emit('change',{level:this.levels,types:this.dataTypes});
    },
    getMapDetail(){
      let params = {
        map_id:this.mapId,
        type:this.setType,
        map_node_ids:this.detail.attr.map_node_ids
      };
      if(this.levelIds){
        params.data_level_str = this.levelIds;
      }
      this.api.dataset.mapNodeDataNums(params).then((res)=>{
        this.tableData = res.info.table_tree;
        this.format();
      })
    },
    format(){
      this.tableData.forEach((item)=> {
        if (item.length > this.maxLengh) {
          this.maxLengh = item.length;
        }
      });

      this.noLimitTotalNum = 0;
      this.tableData.forEach((item,index)=>{
        if(item.length < this.maxLengh){
          let lastId = item[item.length - 1].id;
          let lastName = item[item.length - 1].node.name;
          for(let i=item.length;i<this.maxLengh;i++){
            item.push({
              id:lastId,
              lastName:lastName,
              node_count_detail:item[item.length - 1].node_count_detail
            })
          }
          // set(item[item.length - 1], 'noLimit', 1);

        }
        let arr = this.dataTypes.filter((sItem)=>{
          return sItem.id == this.setType
        })[0].dataList;

        let selectData = arr.length ? arr.filter((sItem)=>{
          return sItem.id == item[item.length - 1].id;
        }) : [];

        if(selectData.length){
          this.$set(item[item.length - 1],'noLimit',selectData[0].noLimit || 0);
          this.$set(item[item.length - 1],'simpleNum',selectData[0].simpleNum || 0);
          this.$set(item[item.length - 1],'generalNum',selectData[0].generalNum || 0);
          this.$set(item[item.length - 1],'difficultyNum',selectData[0].difficultyNum || 0);
          // item[item.length - 1].noLimit = selectData[0].noLimit || 0;
          // item[item.length - 1].simpleNum = selectData[0].simpleNum || 0;
          // item[item.length - 1].generalNum = selectData[0].generalNum || 0;
          // item[item.length - 1].difficultyNum = selectData[0].difficultyNum || 0;
        }else{
          this.$set(item[item.length - 1],'noLimit',0);
          this.$set(item[item.length - 1],'simpleNum',0);
          this.$set(item[item.length - 1],'generalNum',0);
          this.$set(item[item.length - 1],'difficultyNum',0);
        }
        item[item.length - 1].simpleNums = item[item.length - 1].node_count_detail && +item[item.length - 1].node_count_detail.data_types_count['1'] || 0;
        item[item.length - 1].generalNums = item[item.length - 1].node_count_detail && +item[item.length - 1].node_count_detail.data_types_count['2'] || 0;
        item[item.length - 1].difficultyNums = item[item.length - 1].node_count_detail && +item[item.length - 1].node_count_detail.data_types_count['3'] || 0;
        item[item.length - 1].noLimits = item[item.length - 1].simpleNums + item[item.length - 1].generalNums + item[item.length - 1].difficultyNums;


        if(item[item.length - 1].noLimit){
          this.noLimitTotalNum += Number(item[item.length - 1].noLimit);
        }
      })
    },
    changeNoLimit(data){
      if(data.noLimit > data.noLimits){
        data.noLimit = data.noLimits;
      }
      this.noLimitTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].noLimit){
          this.noLimitTotalNum += Number(item[item.length - 1].noLimit);
        }
      })
      if(data.noLimit){
        data.simpleNum = 0;
        data.generalNum = 0;
        data.difficultyNum = 0;

        this.simpleTotalNum = 0;
        this.tableData.forEach((item)=>{
          if(item[item.length - 1].simpleNum){
            this.simpleTotalNum += Number(item[item.length - 1].simpleNum);
          }
        })

        this.generalTotalNum = 0;
        this.tableData.forEach((item)=>{
          if(item[item.length - 1].generalNum){
            this.generalTotalNum += Number(item[item.length - 1].generalNum);
          }
        })

        this.difficultyTotalNum = 0;
        this.tableData.forEach((item)=>{
          if(item[item.length - 1].difficultyNum){
            this.difficultyTotalNum += Number(item[item.length - 1].difficultyNum);
          }
        })
      }
    },
    changeSimpleNum(data,index){
      if(this.tableData[index][this.tableData[index].length - 1].simpleNum > data.simpleNums){
        this.tableData[index][this.tableData[index].length - 1].simpleNum = data.simpleNums;
      }
      this.simpleTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].simpleNum){
          this.simpleTotalNum += Number(item[item.length - 1].simpleNum);
        }
      })
      data.noLimit = 0;
      this.noLimitTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].noLimit){
          this.noLimitTotalNum += Number(item[item.length - 1].noLimit);
        }
      })
    },
    changeGeneralNum(data){
      if(data.generalNum > data.generalNums){
        data.generalNum = data.generalNums;
      }
      this.generalTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].generalNum){
          this.generalTotalNum += Number(item[item.length - 1].generalNum);
        }
      })
      data.noLimit = 0;
      this.noLimitTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].noLimit){
          this.noLimitTotalNum += Number(item[item.length - 1].noLimit);
        }
      })
    },
    changeDifficultyNum(data){
      if(data.difficultyNum > data.difficultyNums){
        data.difficultyNum = data.difficultyNums;
      }
      this.difficultyTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].difficultyNum){
          this.difficultyTotalNum += Number(item[item.length - 1].difficultyNum);
        }
      })
      data.noLimit = 0;
      this.noLimitTotalNum = 0;
      this.tableData.forEach((item)=>{
        if(item[item.length - 1].noLimit){
          this.noLimitTotalNum += Number(item[item.length - 1].noLimit);
        }
      })
    },
    setDataNum(data){
      if(!this.isEdit) return;
      this.setType = data;
      this.dialogVisible = true;
      this.getMapDetail();
    },
    confirmDataNum(){
      this.totalNum();
      this.dialogVisible = false;
      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
    totalNum(){
      let arr = [];
      this.tableData.forEach((item,index)=>{
        if(item[item.length - 1].noLimit || item[item.length - 1].simpleNum || item[item.length - 1].generalNum || item[item.length - 1].difficultyNum){
          arr.push(item[item.length - 1]);
        }
      })
      this.dataTypes.forEach((item)=>{
        if(item.id == this.setType){
          item.totalNum = 0;
          item.dataList = JSON.parse(JSON.stringify(arr));
          item.dataList.forEach((sItem)=>{
            item.totalNum += this.sumNum(sItem);
          })
        }
      })
    },
    sumNum(data){
      let num = 0;
      if(Number(data.noLimit)){
        num += Number(data.noLimit);
      }
      if(Number(data.simpleNum)){
        num += Number(data.simpleNum);
      }
      if(Number(data.generalNum)){
        num += Number(data.generalNum);
      }
      if(Number(data.difficultyNum)){
        num += Number(data.difficultyNum);
      }
      return Number(num);
    },
    cancelDataNum(){
      this.dialogVisible = false;
    },
    changeScore(){
      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
    changeLevel(index){
      if(!this.isEdit) return;
      this.levels[index].status = !this.levels[index].status;
      this.levelIds = this.levels.filter((item)=>{
        return item.status;
      }).map((item)=>{
        return item.id;
      }).join(',');

      this.$emit('change',{level:this.levels,types:this.dataTypes,passScore:this.passScore});
    },
  }
}
</script>

<style scoped lang="scss">
.chapter-map{
  font-size: 14px;
  .display_flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .label-name{
    margin-right: 10px;
    font-weight: bold;
    color: #333333;
  }
  .w120{
    width: 120px;
  }
  .data-num{
    margin-bottom: 20px;
    padding-left: 14px;
    width: 400px;
    height: 40px;
    background: #F6F7FA;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
      color:#5782FF;
      font-weight: bold;
      padding: 0 10px;
    }
  }
  .base-config-tit{
    font-size: 14px;
    color: #999999;
    >span{
      font-weight: bold;
      color: #5782FF;
    }
  }
  .base-config-level{
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 10px;
      width: 80px;
      height: 34px;
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      border-radius: 4px;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active{
      background: #5782FF;
      color: #FFFFFF;
      border: 1px solid #5782FF;
    }
  }
  .types-set-tit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .types-set-tit-str{
      font-weight: bold;
    }
    .types-set-tit-num{
      color: #666666;
      >span{
        padding: 0 10px;
        color:#5782FF;
        font-weight: bold;
      }
    }
  }
  .exam-type{
    margin-top: 20px;
    border: 1px solid #E9E9E9;
    .exam-type-top{
      padding: 0 20px;
      background-color: #F2F3F5;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 16px;
        font-weight: bold;
      }
      .exam-type-top-r{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .exam-type-top-r-t{
          width: 130px;
        }
        .el-input{
          margin-right: 5px;
          flex: 1;
        }
      }
    }
    .exam-type-cont{
      padding: 30px 0;
      .exam-type-cont-set{
        text-align: center;
        >p{
          margin-bottom: 10px;
          color:#409EFF;
        }
      }
      .exam-type-cont-edit{
        padding-left: 20px;
        line-height: 30px;
        .exam-type-cont-nr{
          margin-bottom: 10px;
          .exam-type-cont-nr-tit{
            font-weight: bold;
          }
        }
      }
    }
  }
}
.set-num-tit{
  margin-bottom: 20px;
  font-weight: bold;
}
.set-num-data{
  margin-top: 10px;
  color: #999999;
}
.table-box{
  height: 600px;
  overflow-y: auto;
}
.table-box-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table{
  width: 100%;
  font-size: 14px;
  border: 1px solid #E9E9E9;
  tr{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #E9E9E9;
    overflow: hidden;

    td,th{
      text-align: center;
      border-right: 1px solid #E9E9E9;
      position: relative;
      //display: flex;
      >div{
        display: flex;
        justify-content: center;
        div,p{
          display: flex;
          flex: 1;
          justify-content: center;
          //border-right: 1px solid #dcdee2;
          align-items: center;
          line-height: 20px;

        }
        div:last-child,p:nth-child(2){
          border-right: none;
        }
      }

    }
    td,th{
      .op-btn{
        display: none;
      }
    }
    td:hover,th:hover{
      .op-btn{
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        background-color:rgba(0,0,0,0.7) ;
        color:#FFFFFF;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        >p{
          cursor: pointer;
        }
      }
    }

  }

  tr:last-child{
    border-bottom: none;
  }
}
</style>
