<template>
  <div class="task-info">
    <div class="task-info-nr">
      <p class="task-info-tit">已选择的课程学习任务</p>
      <p class="task-info-name">{{ task.name }}（ID {{ task.id }}）</p>
      <p class="task-info-date">创建时间：{{util.timeFormatter(new Date(+task.created_at*1000), 'yyyy.MM.dd hh:mm')}}</p>
      <div class="task-info-btn">
        <el-button type="primary" @click="showChapters">预览已选章节</el-button>
      </div>
    </div>
    <el-dialog
        title="预览学习任务章节"
        :visible.sync="dialogVisible"
        width="860px"
    >
      <div class="check-task-info">
        <p class="check-task-info-tit">{{ task.name }}（ID {{ task.id }}）</p>
        <p>创建时间：{{util.timeFormatter(new Date(+task.created_at*1000), 'yyyy.MM.dd hh:mm')}}</p>
      </div>
      <div class="chapters-cont-list">
        <div class="chapters-list-item" v-for="item in mapTree" :key="item.id">
          <div  class="chapters-list-item-tit" >
            <div>{{item.node.name}}</div>
          </div>
          <div v-for="(sItem,index) in item?.children" :class="index < item?.children?.length - 1 ? 'bor' : ''" class="chapters-list-item-sub" :key="sItem.id">
            <div class="chapters-list-item-sub-tit" >{{sItem.node.name}}</div>
            <template v-if="sItem?.children?.length">
              <div class="chapters-list-item-sub-list" v-for="(ssItem,sIndex) in sItem?.children" :key="ssItem.id">
                <p>{{ssItem.node.name}}</p>
              </div>
            </template>

          </div>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "taskInfo",
  data(){
    return {
      dialogVisible:false,
      util:util
    }
  },
  props:{
    task:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    mapTree:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  created(){
    // this.getDetail();
  },
  methods:{

    showChapters(){
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped lang="scss">
.task-info{
  .task-info-nr{
    padding: 20px;
    width: 341px;
    background: #F8F8F9;
    border-radius: 1px;
    .task-info-tit{
      margin-bottom:10px ;
      font-size: 16px;
      font-weight: bold;
      color: #5578F6;
    }
    .task-info-date{
      font-size: 12px;
      color: #666666;
    }
    .task-info-btn{

      text-align: center;
      margin: 14px auto 0 auto;
    }
  }
  .check-task-info{
    padding: 20px;
    background: #F6F7FA;
    font-size: 12px;
    color: #666666;
    .check-task-info-tit{
      margin-bottom: 4px;
      font-size: 14px;
      color: #333333;
    }
  }
  .chapters-cont-list{
    margin-top: 20px;
    font-weight: bold;
    color: #333333;
    height: 400px;
    overflow-y: auto;
    .chapters-list-item{
      margin-bottom: 10px;

      .chapters-list-item-tit{
        padding: 0 20px;
        height: 41px;
        background: #F6F7FA;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #dcdee2;
      }
      .chapters-list-item-sub{
        font-weight: 400;
        padding-left: 30px;

        .chapters-list-item-sub-tit{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 30px;
          height: 44px;
        }
        .chapters-list-item-sub-list{
          padding-left: 30px;
          >p{
            height: 44px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 30px;
          }
        }
      }
      .bor{
        border-bottom: 1px solid #E8EAEC;
      }
    }
  }
}
</style>
